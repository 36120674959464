import { logout } from './authorization'

const handleError = (error: object) => {
    if (error && error?.name === 'AxiosError') {
        if (error?.response && error?.response.status === 401) {
            // TODO: It should specify the returnTo url.
            const location = (window as object)?.location
            logout(`${location.origin}/auth/logout`)
        }
        if (error?.code == 'ERR_NETWORK') {
            // navigate('/settings')
        }
    }
    console.log(error)
}

export { handleError }
