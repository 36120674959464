import axios from 'axios'
import { apiBaseUrl } from './config'
import { getAuthorizationHeader, isAuthenticated } from './authorization'
import { useMutation, useQuery } from '@tanstack/react-query'
import queryClient from './queryClient'
import { handleError } from './network'

const resourceURL = `${apiBaseUrl}/api/v1/users/`

enum UserKind {
    OWNER = 'owner',
    RESOURCE = 'resource',
}

interface User {
    id: string
    user_kind?: UserKind
    name: string
    business_id: string
    email: string
    refresh_token: boolean
    auth0_sub?: string
    flag_qr_view: boolean
}

type GetUserMe = (raiseErrors?: boolean) => Promise<User>

const getUserMe: GetUserMe = async (raiseErrors = true) => {
    if (!isAuthenticated()) {
        localStorage.removeItem('userMe')
    }
    const data = localStorage.getItem('userMe')
    if (data && data.length > 2) {
        return JSON.parse(data)
    }
    const config = getAuthorizationHeader()
    try {
        const response = await axios.get(`${resourceURL}me`, config)
        if (response && response.data && response.data.refresh_token) {
            return getUserMe(raiseErrors)
        } else {
            localStorage.setItem('userMe', JSON.stringify(response.data))
            return response.data
        }
    } catch (error) {
        if (raiseErrors) {
            throw new Error('Error fetching data')
        }
    }
}

const useGetUserMeFetch = () => {
    const {
        data: user,
        isLoading: isLoadingUser,
        error: userError,
    } = useQuery(
        {
            queryKey: ['getUserMe'],
            queryFn: getUserMe,
            staleTime: 1000 * 60 * 5,
        },
        queryClient
    )
    return { user, isLoadingUser, userError }
}

type PutUserMe = (objIn: User) => Promise<User>

const putUserMe: PutUserMe = async (objIn) => {
    const config = getAuthorizationHeader()
    try {
        const response = await axios.put(`${resourceURL}me`, objIn, config)
        localStorage.setItem('userMe', JSON.stringify(response.data))
        return response.data
    } catch (error) {
        handleError(error)
    }
}

const useSetUserMeMutation = () => {
    const action = useMutation(
        {
            throwOnError: true,
            mutationFn: async (user: User) => {
                await putUserMe(user)
                return user
            },
            onSuccess: () => {
                // queryClient.invalidateQueries({
                //     queryKey: ['getUserMe'],
                // })
            },
        },
        queryClient
    )
    return action.mutate
}

export { UserKind, User, getUserMe, useGetUserMeFetch, useSetUserMeMutation }
